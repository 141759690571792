import * as React from "react";
import * as Style from "./column.css";

interface IProps {
  children: React.ReactNode;
  classes?: string[];
}

export default ({ children, classes }: IProps) => (
  <div className={[Style.column].concat(classes ? classes : []).join(" ")}>{children}</div>
);
