import * as React from "react";
import Hero from "./components/hero";
import Row from "./components/row";
import Column from "./components/column";
import Card from "./components/card";
import Quote from "./components/quote";
import Offer from "./components/offer";
import * as Style from "./app.css";
import * as ColStyle from "./components/column.css";

const QuoteChristoffer = require("../resources/christoffer.png");
const PackageFamily = require("../resources/package-family.jpg");
const PackageTree = require("../resources/package-tree.jpg");
const PackageCustom = require("../resources/package-custom.jpg");
const PackageFamilyPDF = require("../resources/familjeportratt.pdf");
const PackageTreePDF = require("../resources/slakttrad.pdf");

export default () => (
  <div>
    <Hero />
    <Row>
      <div className={Style.contentwrapper}>
        <Column classes={[ColStyle.seven]}>
          <h1>Jag hjälper dig att hitta dina rötter</h1>
          <p>
            Vad hette mormorsmor och vad gjorde hon när hon levde. Har ni verkligen italienare i
            släkten, eller finns det något roligt gammalt släktnamn som du kan ta efter? Hur mycket
            vet du egentligen om din släkt?
          </p>
          <p>
            Jag heter Karolina och jag har släktforskat i över 20 år och jag kan hjälpa dig att
            hitta dina anor. Nedan ser du några av mina paketerbjudanden, men tveka inte med att
            kontakta mig så kan vi se vad jag skulle kunna hjälpa just dig med.
          </p>
          <h2>Hur funkar det?</h2>
          <p>
            När jag fått information från dig gällande din utgångsperson och känner till dina
            önskmål, gör jag en första kontroll och återkommer till dig med ett pris, först då
            behöver du ta ställning till om du vill att jag ska påbörja en utredning.
          </p>
          <h2>Det enda jag behöver är</h2>
          <p>
            Födelsedag, namn och gärna födelseort på den person vi utgår ifrån. Uppgifterna behöver
            vara för en person född senast 1959 och då bosatt i Sverige för att vara sökbar.
          </p>
        </Column>
        <Column classes={[ColStyle.half]}> </Column>
        <Column classes={[ColStyle.three]}>
          <Card>
            <h2>Min kvalitetsgaranti</h2>
            <hr style={{ borderBottom: "0px", borderTop: "1px solid white", margin: "0.5em 0" }} />
            <p>
              Jag forskar i kyrkoarkivens födelseböcker, dödböcker och vigselböcker samt
              husförhörslängder. Det är alltså i dokumenten som prästerna själva skrev för att föra
              bok över relevant information som jag använder mig av.
            </p>

            <p>
              Jag hänvisar alltid till källan (bok och sida) där jag hittat dina släktingar i den
              dokumentation du får av mig.
            </p>
            <p>
              Jag använder främst digitala arkiv som{" "}
              <a
                href="https://www.arkivdigital.se/"
                target="_blank"
                className={[Style.linkwhite, Style.iconhref].join(" ")}
                rel="noreferrer"
              >
                Arkiv Digital
              </a>{" "}
              och{" "}
              <a
                href="https://riksarkivet.se/"
                target="_blank"
                className={[Style.linkwhite, Style.iconhref].join(" ")}
                rel="noreferrer"
              >
                Riksarkivet/Svar
              </a>
            </p>
          </Card>
        </Column>
      </div>
    </Row>
    <Row classNames={[Style.brown]}>
      <Column classes={[ColStyle.six, ColStyle.nineMedium]}>
        <Quote
          text="Nu när barnen blivit äldre så stärks banden till det förflutna. Att tillsammans upptäcka
            våra rötter genom mormorsmor har fört oss närmare varandra."
          cite="Christoffer Andersson, Umeå"
          image={QuoteChristoffer}
        />
      </Column>
    </Row>
    <Row classNames={[Style.nopaddingbottom]}>
      <Column classes={[ColStyle.ten]}>
        <h1 style={{ textAlign: "center" }}>Exempel på vad jag kan hjälpa dig med</h1>
      </Column>
    </Row>
    <Row classNames={[Style.nopaddingtop]}>
      {/* <div style={{ maxWidth: "72rem", display: "flex", justifyContent: "center" }}> */}
      <div className={Style.contentwrapper}>
        <Column classes={[ColStyle.five]}>
          <Offer
            heading="Familjeporträtt"
            price="PRIS: 1200 SEK - 1500 SEK"
            image={PackageFamily}
            text="En personutredning för en individ, från födelse till död. Ett personporträtt inkluderar föräldrar, barn, giftemål och ev. övrig relevant information som går att hitta i kyrkböckerna om individen. Självklart hänvisar jag till de källor som använts."
            linkText="Exempel familjeporträtt"
            linkUrl={PackageFamilyPDF}
          />
        </Column>

        {/* <Column classes={[ColStyle.half]}> </Column> */}
        <Column classes={[ColStyle.five]}>
          <Offer
            heading="Släktträd"
            price="PRIS: 1800 SEK - 2100 SEK"
            image={PackageTree}
            text="Släkten i rakt uppstigande led, 3 generationer bakåt från centrumperson, samtliga släktlinjer eller så många som är möjliga att hitta. Det kan bli max 14 individer."
            linkText="Exempel släktträd"
            linkUrl={PackageTreePDF}
          />
        </Column>
        <Column classes={[ColStyle.five]}>
          <Offer
            heading="Anpassat efter dina behov"
            price="PRIS: FRÅN 500 SEK"
            image={PackageCustom}
            text="Jag anpassar mig efter dina behov, berätta vad du är ute efter så återkommer jag med ett kostnadsförslag."
          />
        </Column>
      </div>
      {/* </div> */}
    </Row>
    <Row classNames={[Style.nomarginbottom, Style.nopaddingbottom, Style.black]}>
      <Column classes={[ColStyle.ten]}>
        <h1 style={{ textAlign: "center" }} className={Style.nomarginbottom}>
          Kontakta mig för släktforskningshjälp
        </h1>
      </Column>
    </Row>
    <Row classNames={[Style.black, Style.nomarginbottom]}>
      <Column>
        <p>
          Om det här låter intressant kan du kontakta mig på{" "}
          <a href="mailto:karolina@mormorsmor.se" className={Style.me}>
            karolina@mormorsmor.se
          </a>{" "}
        </p>
        <p>Innehar F-Skatt</p>
      </Column>
    </Row>
  </div>
);
