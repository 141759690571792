import * as React from "react";
import Column from "./column";
import Row from "./row";
import * as Style from "./quote.css";

interface IProps {
  text: string;
  cite: string;
  image: string;
}

export default ({ image, text, cite }: IProps) => (
  <>
    {/* <img src={image} className={Style.quoteimage} style={{ alignSelf: "center" }} /> */}
    <blockquote className={Style.blockquote}>
      {text}
      <cite className={Style.cite}>{cite}</cite>
    </blockquote>
  </>
);
