import * as React from "react";
import Column from "./column";
import * as Style from "./offer.css";

interface IProps {
  image: string;
  heading: string;
  price: string;
  text: string;
  linkUrl?: string;
  linkText?: string;
}

export default ({ image, text, price, heading, linkText, linkUrl }: IProps) => (
  <div className={Style.wrapper}>
    <div className={Style.imagewrapper}>
      <img src={image} className={[Style.image, Style.animate].join(" ")} />
    </div>
    <div className={Style.content}>
      <h2>{heading}</h2>
      <span className={Style.price}>{price}</span>
      <p>{text}</p>
      {linkUrl && (
        <a href={linkUrl} className={Style.iconpdf} target="_blank">
          {linkText}
        </a>
      )}
    </div>
  </div>
);
