import * as React from "react";
import * as Style from "./row.css";

interface IProps {
  children: React.ReactNode;
  classNames?: string[];
}

export default (props: IProps) => (
  <div className={[Style.row].concat(props.classNames!).join(" ")} style={{ marginBottom: "20px" }}>
    {props.children}
  </div>
);
